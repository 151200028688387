import {Heading, Paragraph, TabMenu} from '@halp/ui';
import {useQuery} from '@halp/api/graphql';
import type {CoachingFunnelStage} from '@halp/util/constants';
import {COACHING_FUNNEL_STAGES} from '@halp/util/constants';
import {snakeToCamel} from '@halp/util';
import {useUserType} from '../../Users';
import {Paths} from '../../Paths';
import {useI18n} from '../../i18n';
import {StudentsTable} from './StudentsTable';
import {CoachStudentsByFunnelStageDocument} from './CoachStudentsByFunnelStage.query';
import style from './CoachStudentsTableContainer.module.css';
import type {StudentsTableColumnsType} from './StudentsTableColumns';

interface StudentsCountPerFunnelStage {
	getStarted: number;
	discover: number;
	prep: number;
	submit: number;
	accepted: number;
	visa: number;
	setUp: number;
	placed: number;
}
type CamelFunnelStages = keyof StudentsCountPerFunnelStage;

type StudentStatus = CoachingFunnelStage | 'ARCHIVED' | 'ALL';

interface Props {
	prefix: string;
	coachId: string;
	studentStatus?: StudentStatus;
}

function StudentFunnelStageCount({count}: {count: number}) {
	return (
		<Paragraph type="subtitle2" className={style.StudentFunnelStageCount}>
			{count}
		</Paragraph>
	);
}

function buildTableColumns(
	studentStatus?: StudentStatus,
): StudentsTableColumnsType[] {
	if (!studentStatus) {
		return [
			'name',
			'contact',
			'country',
			'score',
			'priority',
			'studyDate',
			'funnelStage',
			'deadline',
		];
	}

	if (studentStatus === 'DISCOVER' || studentStatus === 'PREP') {
		return [
			'name',
			'contact',
			'country',
			'studyLocation',
			'priority',
			'studyDate',
			'programRecommendation',
		];
	}

	if (studentStatus === 'SUBMIT') {
		return [
			'name',
			'contact',
			'country',
			'priority',
			'studyDate',
			'application',
		];
	}

	if (studentStatus === 'ACCEPTED' || studentStatus === 'VISA') {
		return ['name', 'contact', 'country', 'priority', 'studyDate', 'deadline'];
	}

	if (studentStatus === 'ARCHIVED') {
		return [
			'name',
			'contact',
			'country',
			'score',
			'studyDate',
			'archivalReason',
		];
	}

	return ['name', 'contact', 'country', 'priority', 'studyDate'];
}

function getSortColumn(studentStatus?: StudentStatus) {
	if (studentStatus === 'ARCHIVED') {
		return 'insertedAt';
	}

	return 'priority';
}

export function CoachStudentsTableContainer({
	prefix,
	coachId,
	studentStatus,
}: Props) {
	const {t} = useI18n();
	const userType = useUserType();

	const {data} = useQuery(CoachStudentsByFunnelStageDocument, {
		variables: {coachId: coachId},
	});

	const studentsByFunnelStage = data?.coach?.studentsByFunnelStage;
	const archivedStudents = data?.coach?.studentsByArchiveState.archived ?? 0;

	const totalStudentsCount = studentsByFunnelStage
		? Object.values(studentsByFunnelStage).reduce<number>((a, b) => {
				if (typeof a === 'number' && typeof b === 'number') {
					return a + b;
				}
				return a;
			}, 0)
		: 0;

	const paths: Record<StudentStatus, string> = {
		ALL: Paths.users.coaching.all.url(coachId, userType),
		GET_STARTED: Paths.users.coaching.getStarted.url(coachId, userType),
		DISCOVER: Paths.users.coaching.discover.url(coachId, userType),
		PREP: Paths.users.coaching.prep.url(coachId, userType),
		SUBMIT: Paths.users.coaching.apply.url(coachId, userType),
		ACCEPTED: Paths.users.coaching.accepted.url(coachId, userType),
		VISA: Paths.users.coaching.visa.url(coachId, userType),
		SET_UP: Paths.users.coaching.arrive.url(coachId, userType),
		PLACED: Paths.users.coaching.placed.url(coachId, userType),
		ARCHIVED: Paths.users.coaching.archived.url(coachId, userType),
	};

	const funnelStageTabs = COACHING_FUNNEL_STAGES.map((stage) => {
		const funnelStageCamel = snakeToCamel(stage) as CamelFunnelStages;
		const count = funnelStageCamel
			? studentsByFunnelStage?.[funnelStageCamel]
			: 0;
		return {
			text: t(`coaching.tabs.${stage.toLowerCase()}`),
			to: paths[stage],
			children: count !== undefined && (
				<StudentFunnelStageCount count={count} />
			),
		};
	});

	const tabs = [
		{
			text: t('coaching.tabs.all'),
			to: paths.ALL,
			children: <StudentFunnelStageCount count={totalStudentsCount} />,
		},
		...funnelStageTabs,
		{
			text: t('coaching.tabs.archived'),
			to: paths.ARCHIVED,
			children: <StudentFunnelStageCount count={archivedStudents} />,
		},
	];

	return (
		<>
			<Heading type="h2" spacing="xl">
				{t('coaching.title')}
			</Heading>
			<TabMenu tabStyle="underline" tabs={tabs} />
			<StudentsTable
				prefix={prefix}
				coachId={coachId}
				columns={buildTableColumns(studentStatus)}
				filters={['onshore', 'intakePeriod', 'country']}
				sortColumn={getSortColumn(studentStatus)}
				studentStatus={studentStatus}
			/>
		</>
	);
}
